import React, { useState } from "react";

const NewPasswordForm = ({ onChangePassword }) => {

  const [newPassword, setNewPassword] = useState(""); // Add this line

  const handleSubmit = (e) => {
    e.preventDefault();
    onChangePassword(newPassword);
  };

  return (
    <div className="NewPasswordForm">
      <h2>Change Password</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="new-password">New Password:</label>
        <input
          type="password"
          id="new-password"
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <button type="submit">Change Password</button>
      </form>
    </div>
  );
};

export default NewPasswordForm;
