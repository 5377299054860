import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import { useTrifecta } from './Context/TrifectaContext';

const TrifectaCharts = () => {

  const {
    calculatedResults
  } = useTrifecta();

  // Prepare data for the charts, grouped by year
  const chartDataByYear = {
    '2021': [],
    '2022': [],
    '2023': []
  };  

  const parseDateString = (dateStr) => {
    const year = dateStr.substring(0, 4);
    const month = dateStr.substring(4, 6);
    const day = dateStr.substring(6, 8);
    return new Date(`${year}-${month}-${day}`);
  };

  calculatedResults.forEach(item => {
    const year = parseDateString(item.date).getFullYear().toString();
    if (chartDataByYear.hasOwnProperty(year)) {
      chartDataByYear[year].push({
        date: item.formattedDate,
        bankAfterRace: item.bankAfterRace,
      });
    }
  });

  const renderLineChart = (data, year) => (
    <div key={year}>
      <h3>{year}</h3>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis width={100} />
          <Tooltip />
          <Line type="monotone" dataKey="bankAfterRace" stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );

  return (
    <>
    <h2>Bank Over Time</h2>
    <div>
      {Object.keys(chartDataByYear).map(year => renderLineChart(chartDataByYear[year], year))}
    </div>
    </>
  );
};

export default TrifectaCharts;
