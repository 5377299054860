import React, { useEffect } from "react";

import TrifectaSummary from './TrifectaSummary';
import TrifectaCharts from './TrifectaCharts';
import TrifectaControls from './TrifectaControls';
import TrifectaMonthlyBreakdown from './TrifectaMonthlyBreakdown';
import TrifectaPerRaceBreakdown from './TrifectaPerRaceBreakdown';

import { useTrifecta } from './Context/TrifectaContext';

import "./Trifecta.css";
import { 
  calculateAverageMonthsToDouble,
  getProcessedResults,  
  groupResultsByMonth
} from "./Utilities/TrifectaResultsCalculations";

const TrifectaResults = ({ rawResults }) => {

  let {
    startingBank
  } = useTrifecta();

  const {
    betSizeType, 
    betSize, 
    setAverageOdds,
    setAverageMonthlyGain,
    setFinishingBank,
    setMaxDrawdown,
    setLargestOddsWin,
    setWinningStreak,
    setLosingStreak,
    setCalculatedResults,
    setGroupedResults,
    setNetProfitLoss,
    setAverageMonthsToDouble,
    setTotalPercentageGain
  } = useTrifecta();

  useEffect(() => {
    if (!rawResults || rawResults.length === 0) return;

    const {
      results,
      maxWinStreak,
      maxLoseStreak,
      largestOddsWin,
      maxDrawdown,
      finishingBank,
      netProfitLoss,
      totalPercentageGain,
      averageOdds
    } = getProcessedResults(
      rawResults, 
      startingBank, 
      betSizeType,
      betSize,
    );

    setAverageOdds(averageOdds);
    setCalculatedResults(results);
    setWinningStreak(maxWinStreak);
    setLosingStreak(maxLoseStreak);
    setLargestOddsWin(largestOddsWin);    
    setMaxDrawdown(maxDrawdown);
    setFinishingBank(finishingBank);
    setNetProfitLoss(netProfitLoss);
    setTotalPercentageGain(totalPercentageGain);

    const {
      groupedByMonthResults, 
      averageMonthlyGain 
    } = groupResultsByMonth(
      startingBank,
      results
    );

    setAverageMonthlyGain(averageMonthlyGain);
    setGroupedResults(groupedByMonthResults);

    const averageMonthsToDouble = calculateAverageMonthsToDouble(
      startingBank,
      groupedByMonthResults
    );

    console.log('average months to double: ' + averageMonthsToDouble);
    setAverageMonthsToDouble(averageMonthsToDouble);
  }, [
    betSizeType,
    betSize,
    startingBank
  ]);

  if (!rawResults || rawResults.length === 0) {
    return <div>Loading...</div>;
  }


  return (
    <div className="TrifectaResults-table">
      <TrifectaCharts />
      <TrifectaControls />
      <TrifectaSummary />
      <TrifectaMonthlyBreakdown />      
      <TrifectaPerRaceBreakdown />
  </div>
  );
};


export default TrifectaResults;