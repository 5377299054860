import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

const ResultsChart = ({ results }) => {
  if (!results || results.length === 0) {
    return <div>Loading...</div>;
  }

  const monthlyResults = results.monthly;

  // Find the maximum value in the results
  const maxValue = Math.max(
    ...monthlyResults.map(
      (result) => parseFloat(result.total_balance)
    )
  );
  
  // Find the maximum value in the results
  const minValue = Math.min(
    ...monthlyResults.map(
      (result) => parseFloat(result.total_balance)
    )
  );
  
  // Calculate the ticks for the Y-axis with 1000 increments
  const yAxisTicks = [];
  for (let i = -500; i <= maxValue + 500; i += 500) {
    yAxisTicks.push(i);
  }

  return (
    <ResponsiveContainer width="100%" height={600}>
      <LineChart
        data={monthlyResults}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="timestamp"
          tickFormatter={(unixTime) =>
            new Date(unixTime * 1000).toLocaleString("default", {
              month: "short",
              year: "numeric",
            })
          }
          angle={-90}
          textAnchor="end"
        />
        <YAxis domain={[minValue - 500, maxValue + 500]} ticks={yAxisTicks} />
        <Tooltip />
        <Legend
          verticalAlign="bottom"
          align="center"
          layout="horizontal"
          wrapperStyle={{
            paddingTop: "100px",
            paddingBottom: "15px",
          }}
        />
        <Line type="monotone" dataKey="deposit_total" stroke="#8884d8" activeDot={{ r: 8 }} name="Deposits" />
        <Line type="monotone" dataKey="total_balance" stroke="#82ca9d" name="Total Balance" />
        <Line type="monotone" dataKey="profit_loss_total" stroke="#FF0000" name="P & L" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ResultsChart;
