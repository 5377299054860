import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import "./Tables.css";

const calculateSummary = (transactions) => {
  if (!transactions || transactions.length === 0) {
    return <div>Loading...</div>;
  }

  const summary = {};

  transactions.forEach((transaction) => {
    const name = transaction.details.name;
    const amount = parseFloat(transaction.details.amount);

    if (summary[name]) {
      summary[name] += amount;
    } else {
      summary[name] = amount;
    }
  });

  // Convert summary object to array of objects for DataGrid
  return Object.keys(summary).map((name) => ({
    name,
    totalAmount: summary[name]
  }));
};  

const SyndiTransactions = ({ transactions }) => {
  const flattenTransactions = transactions.map((transaction) => {
    return {
      ...transaction,
      'details.name': transaction.details.name,
      'details.amount': transaction.details.amount
    };
  });

  const summaryData = calculateSummary(transactions);
  
  const summaryColumns = [
    { field: 'name', headerName: 'Name', width: 150 },
    { 
      field: 'totalAmount', 
      headerName: 'Total Amount', 
      width: 150,
      valueFormatter: ({ value }) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)
    },
  ];
  
  const columns = [
    {
      field: "timestamp",
headerName: "Date",
      width: 150,
      valueFormatter: (timestamp) => {
        const date = new Date(timestamp.value * 1000);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
      },
      valueGetter: (timestamp) => {
        return timestamp.value;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
          return cellParams1.value - cellParams2.value
      },
      headerClassName: 'header',
    },
    { 
      field: "details.name", 
      headerName: "Name", 
      width: 150,
      headerClassName: 'header',
    },
    { 
      field: "details.amount", 
      headerName: "Amount", 
      width: 100,
      valueGetter: (params) => parseFloat(params.row.details.amount),
      valueFormatter: ({ value }) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value),
      headerClassName: 'header',
    },
  ].map((column) => ({
    ...column,
    getCellStyle: (params) => {
      const color = params.rowIndex % 2 ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.05)';
      return { backgroundColor: color };
    },
  }));

  return (
    <div className="SyndiTransactions-table">
      <h2>Summary</h2>
      <DataGrid 
        rows={summaryData} 
        columns={summaryColumns}
        getRowId={(row) => row.name} 
        className="DataGrid-cell"
      />

      <h2>Transactions</h2>        
      <DataGrid 
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'timestamp',
                sort: 'desc',
              },
            ]
          },
        }}
        rows={flattenTransactions} 
        columns={columns} 
        getRowId={(row) => row.transaction_uuid} 
        className="DataGrid-cell"
      />
    </div>
  );
};

export default SyndiTransactions;
