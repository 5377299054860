import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import "./Tables.css";

const calculateSummary = (bets) => {
  let totalBets = 0;
  let totalReturn = 0;
  let totalStake = 0;
  let totalOdds = 0;

  bets.forEach((bet) => {
    totalBets += 1;
    totalReturn += parseFloat(bet.details.dividend);
    totalStake += parseFloat(bet.details.stake);
    totalOdds += parseFloat(bet.details.odds);
  });

  const avgOdds = totalOdds / totalBets;
  const avgStake = totalStake / totalBets;
  const percentageGainOrLoss = ((totalReturn - totalStake) / totalStake) * 100;
  const totalProfitOrLoss = totalReturn - totalStake

  return [
    { id: 1, fieldName: 'totalBets', field: 'Total Bets', value: totalBets },
    { id: 2, fieldName: 'avgOdds', field: 'Average Odds', value: avgOdds },
    { id: 3, fieldName: 'avgStake', field: 'Average Stake', value: avgStake },
    { id: 4, fieldName: 'totalStake', field: 'Total Stake', value: totalStake },
    { id: 5, fieldName: 'totalReturn', field: 'Total Return', value: totalReturn },
    { id: 6, fieldName: 'totalProfitOrLoss', field: 'Total Profit / Loss', value: totalProfitOrLoss },
    { id: 7, fieldName: 'percentageGainOrLoss', field: '% Gain or Loss', value: percentageGainOrLoss },
  ];

};


const SyndiBets = ({ syndiBets }) => {
  if (!syndiBets || syndiBets.length === 0) {
    return <div>Loading...</div>;
  }

  const summaryDataAsRows = calculateSummary(syndiBets).map(({id, field, value}) => ({
    id,
    field, // this already is the field label
    value,
  }));

  const summaryColumns = [
    { 
      field: 'field', 
      headerName: 'Statistic', 
      width: 150
    },
    { 
      field: 'value', 
      headerName: 'Value', 
      width: 150,
      valueFormatter: (params) => {
        switch(params.id) {
          case 1:
            return params.value.toFixed(0); // format as int
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(params.value); // format as currency
          case 7:
            return params.value.toFixed(2) + '%'; // format as percentage
          default:
            return params.value; // fallback
        }
      },
    },
  ];



  const flattenBets = syndiBets.map((bet) => {
    return {
      ...bet,
      'details.dividend': bet.details.dividend,
      'details.odds': bet.details.odds,
      'details.race': bet.details.race,
      'details.refund': bet.details.refund,
      'details.stake': bet.details.stake,
      'details.tab': bet.details.tab,
      'details.track': bet.details.track
    };
  });

  const columns = [
    {
      field: "timestamp",
      headerName: "Date",
      width: 150,
      valueFormatter: (timestamp) => {
        const date = new Date(timestamp.value * 1000);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
      },
      valueGetter: (timestamp) => {
        return timestamp.value;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
          return cellParams1.value - cellParams2.value
      },
      headerClassName: 'header',
    },
    {
      field: "selection",
      headerName: "Selection",
      width: 150,
      cellClassName: 'capitalize',
      headerClassName: 'header',
    },
    { 
      field: "details.track", 
      headerName: "Track", 
      width: 150,
      headerClassName: 'header',
    },
    { 
      field: "details.race", 
      headerName: "Race", 
      width: 100,
      headerClassName: 'header',
    },
    { 
      field: "details.dividend", 
      headerName: "Return", 
      width: 100,
      valueGetter: (params) => params.row.details.dividend,
      valueFormatter: ({ value }) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value),
      headerClassName: 'header',
    },
    { 
      field: "details.odds", 
      headerName: "Odds", 
      width: 100,
      valueGetter: (params) => params.row.details.odds,
      valueFormatter: ({ value }) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value),
      headerClassName: 'header',
    },
    { 
      field: "details.stake", 
      headerName: "Stake", 
      width: 100,
      valueGetter: (params) => params.row.details.stake,
      valueFormatter: ({ value }) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value),
      headerClassName: 'header',
    },
    { 
      field: "details.refund", 
      headerName: "Refund", 
      width: 100,
      valueGetter: (params) => params.row.details.refund,
      valueFormatter: ({ value }) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value),
      headerClassName: 'header',
    },
  ].map((column) => ({
    ...column,
    getCellStyle: (params) => {
      const color = params.rowIndex % 2 ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.05)';
      return { backgroundColor: color };
    },
  }));

  return (
    <div className="SyndiBets-table">
      <h2>Summary</h2>
      <DataGrid 
        rows={summaryDataAsRows} 
        columns={summaryColumns} 
        className="DataGrid-cell summary-table"
        autoHeight 
        disableColumnMenu 
        hideFooterPagination 
        hideFooterRowCount 
        hideFooterSelectedRowCount
        hideFooter
      />

      <h2>Bets</h2>      
      <DataGrid 
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'timestamp',
                sort: 'desc',
              },
            ]
          },
        }}
        rows={flattenBets} 
        columns={columns} 
        getRowId={(row) => row.syndi_bet_id} 
        className="DataGrid-cell"
      />
    </div>
  );
};

export default SyndiBets;
