import React from 'react';
import { DataGrid } from "@mui/x-data-grid";

import { useTrifecta } from './Context/TrifectaContext';

const TrifectaMonthlyBreakdown = () => {

  console.log('test2');

  const {
    groupedResults
  } = useTrifecta();

  // Utility function to format number as currency
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  // Define columns for DataGrid
  const columns = [
    {
      field: "formattedDate",
      headerName: "Date",
      width: 150,
      headerClassName: 'header',
    },
    {
      field: "raceWinnings",
      headerName: "Total Monthly Winnings",
      width: 200,
      headerClassName: 'header',
      valueFormatter: ({ value }) => currencyFormatter.format(value)
    },
    {
      field: "bankAfterRace",
      headerName: "Bank After Month",
      flex: 1,
      minWidth: 150,
      headerClassName: 'header',
      valueFormatter: ({ value }) => currencyFormatter.format(value)
    },
    {
      field: "percentageChange",
      headerName: "Monthly % Change",
      minWidth: 200,
      headerClassName: 'header',
      valueFormatter: (params) => {
        if (params.value == null) return 'N/A';
        return `${params.value.toFixed(2)}%`;
      }
    },
    // Other column definitions...
  ];

  const calculateTableWidth = (columns) => {
    return columns.reduce((width, column) => width + (column.minWidth || 150), 0);
  };

  const tableWidth = calculateTableWidth(columns);

  return (
    <>
    <h2>Monthly Breakdown</h2>
    <div className="TrifectaResults-table" style={{ width: tableWidth }}>
      <DataGrid 
        rows={groupedResults}
        className="DataGrid-cell"
        columns={columns}
        getRowClassName={(params) =>
          params.row.percentageChange > 0 ? 'winner-true' : 'winner-false'
        }
      />
    </div>
    </>
  );
};

export default TrifectaMonthlyBreakdown;
