import React from 'react';

import { useTrifecta } from './Context/TrifectaContext';

const TrifectaSummary = () => {

    const { 
        startingBank, 
        finishingBank, 
        netProfitLoss, 
        totalPercentageGain, 
        maxDrawdown, 
        largestOddsWin, 
        winningStreak, 
        losingStreak, 
        averageMonthsToDouble,
        betSizeType,
        betSize,
        averageMonthlyGain,
        averageOdds
    } = useTrifecta()

    return (
        <>
        <h2>Summary</h2>
        <div className="summary-section">
            <p className="summary-item">Bet Size Type: {betSizeType}</p>
            <p className="summary-item">Bet Size: {betSize}</p>
            <p className="summary-item">Starting Bank Size: ${startingBank.toFixed(2)}</p>
            <p className="summary-item">Finishing Bank Size: ${finishingBank.toFixed(2)}</p>
            <p className="summary-item">Net Profit / Loss: ${netProfitLoss.toFixed(2)}</p>
            <p className="summary-item">Total Percentage Gain: {totalPercentageGain.toFixed(2)}%</p>
            <p className="summary-item">Average Monthly Gain %: {averageMonthlyGain.toFixed(2)}</p>
            <br/>
            <p className="summary-item">Maximum Drawdown: {maxDrawdown.toFixed(2)}%</p>
            <p className="summary-item">Largest Odds Win: {largestOddsWin}</p>
            <p className="summary-item">Average Odds: {averageOdds.toFixed(2)}</p>
            <p className="summary-item">Longest Winning Streak: {winningStreak}</p>
            <p className="summary-item">Longest Losing Streak: {losingStreak}</p>
            <p className="summary-item">Average Months to Double Bank: {averageMonthsToDouble.toFixed(2)}</p>
        </div>
        </>
    );
};

export default TrifectaSummary;