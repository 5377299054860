import React from 'react';
import { DataGrid } from "@mui/x-data-grid";

import { useTrifecta } from './Context/TrifectaContext';

const TrifectaPerRaceBreakdown = () => {

  const {
   calculatedResults 
  } = useTrifecta();

  // Utility function to format number as currency
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  // Define columns for DataGrid
  const columns = [
    {
      field: "formattedDate",
      headerName: "Date",
      width: 150,
      headerClassName: 'header',
    },
    {
      field: "resultDate",
      headerName: "Date",
      width: 150,
      headerClassName: 'header',
    },
    {
      field: "locationRaceNumber",
      headerName: "Location / Race Number",
      width: 150,
      headerClassName: 'header',
    },
    {
      field: "win",
      headerName: "Winner",
      width: 150,
      headerClassName: 'header',
    },
    {
      field: "odds",
      headerName: "Odds",
      width: 150,
      headerClassName: 'header',
    },
    {
      field: "raceWinnings",
      headerName: "Race Winnings",
      width: 150,
      headerClassName: 'header',
      valueFormatter: ({ value }) => currencyFormatter.format(value)
    },
    {
      field: "bankAfterRace",
      headerName: "Bank After Race",
      width: 150,
      headerClassName: 'header',
      valueFormatter: ({ value }) => currencyFormatter.format(value)
    },
    // Other column definitions...
  ];

  return (
    <>
    <h2>Per-Race Breakdown</h2>
    <div className="TrifectaResults-table">
      <DataGrid 
        rows={calculatedResults}
        className="DataGrid-cell"
        columns={columns}
        getRowClassName={(params) =>
          params.row.win ? 'winner-true' : 'winner-false'
        }
      />
    </div>
    </>
  );
};

export default TrifectaPerRaceBreakdown;
