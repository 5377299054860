export const getProcessedResults = (
    rawResults,
    startingBank, 
    betSizeType,
    betSize, 
    ) => {
    let localBank = startingBank; // Reset local bank to starting value
    let localMaxBank = localBank;
    let currentWinStreak = 0, maxWinStreak = 0;
    let currentLoseStreak = 0, maxLoseStreak = 0;
    let largestOddsWin = 0;
    let maxDrawdown = 0;
    let betAmount = 0;
    let totalOdds = 0, totalWins = 0;

    // Enhance results with bank, winnings, and add a unique id
    const results = rawResults.map((result, index) => {
      if (betSizeType === 'percentage') {
        betAmount = localBank * (betSize / 100);
      } else if (betSizeType === 'flat') {
        betAmount = betSize;
      }
      const win = result.winner;
      const odds = result.odds;
      const winnings = win ? (odds * (betAmount / 168)) - betAmount : -betAmount;
      localBank += winnings;
      const resultDate = result.date;
      const locationRaceNumber = result.location_race_number;

      // Update statistics
      if (win) {
        currentWinStreak++;
        currentLoseStreak = 0;
        largestOddsWin = Math.max(largestOddsWin, odds);
        totalOdds += odds;
        totalWins++;
      } else {
        currentLoseStreak++;
        currentWinStreak = 0;
      }

      maxWinStreak = Math.max(maxWinStreak, currentWinStreak);
      maxLoseStreak = Math.max(maxLoseStreak, currentLoseStreak);

      localMaxBank = Math.max(localMaxBank, localBank);
      let currentDrawdown = ((localMaxBank - localBank) / localMaxBank) * 100;
      maxDrawdown = Math.max(maxDrawdown, currentDrawdown)

      // Convert timestamp to date and format it
      const date = new Date(parseInt(result.timestamp, 10) * 1000);
      const formattedDate = date.toLocaleString('default', { month: 'long', year: 'numeric' });            

      return {
        id: index, // Unique id for each row
        ...result,
        resultDate,
        locationRaceNumber,
        win,
        odds,
        bankAfterRace: localBank,
        raceWinnings: winnings,
        formattedDate
      };
    });

    const netProfitLoss = (localBank - startingBank);
    const totalPercentageGain = ((localBank - startingBank) / startingBank) * 100;
    const averageOdds = totalWins > 0 ? totalOdds / totalWins : 0;

    return {
      results,
      maxWinStreak,
      maxLoseStreak,
      largestOddsWin,
      maxDrawdown,
      finishingBank: localBank,
      netProfitLoss,
      totalPercentageGain,
      averageOdds
    };
};

export const groupResultsByMonth = (
    startingBank,
    results
    ) => {
    const groupedByMonth = {};
    let previousMonthEndBank = startingBank;
    let monthlyGains = [];

    const groupedByMonthResults = [];

    let idCounter = 0; // Initialize a counter for unique IDs

    // Sort results by date to ensure correct monthly ordering
    const sortedResults = [...results].sort((a, b) => new Date(a.date) - new Date(b.date));

    sortedResults.forEach((result, index) => {
      const monthYearKey = result.formattedDate;
      if (!groupedByMonth[monthYearKey]) {
        // When encountering a new month, push the previous month's data to groupedByMonthResults
        if (index > 0) {
          const previousMonthData = groupedByMonth[sortedResults[index - 1].formattedDate];
          const monthlyGain = ((previousMonthData.bankAfterRace - previousMonthEndBank) / previousMonthEndBank) * 100;
          monthlyGains.push(monthlyGain); // Add the gain to the array

          groupedByMonthResults.push({
            ...previousMonthData,
            percentageChange: monthlyGain
          });
          previousMonthEndBank = previousMonthData.bankAfterRace; // Update end bank for the previous month
        }
        // Initialize the new month
        groupedByMonth[monthYearKey] = {
          id: idCounter++,
          formattedDate: monthYearKey,
          raceWinnings: 0,
          bankAfterRace: startingBank,
          percentageChange: null,
        };
      }
      // Accumulate winnings and update bankAfterRace for the current month
      groupedByMonth[monthYearKey].raceWinnings += result.raceWinnings;
      groupedByMonth[monthYearKey].bankAfterRace = result.bankAfterRace;
    });    

    // Add the last month's data after the loop
    const lastMonthKey = sortedResults[sortedResults.length - 1].formattedDate;
    const lastMonthData = groupedByMonth[lastMonthKey];
    groupedByMonthResults.push({
      ...lastMonthData,
      percentageChange: ((lastMonthData.bankAfterRace - previousMonthEndBank) / previousMonthEndBank) * 100,
    });
    const averageMonthlyGain = monthlyGains.length > 0 ? monthlyGains.reduce((a, b) => a + b, 0) / monthlyGains.length : 0;

    console.log('inside: ' + groupedByMonthResults);

    return {
      groupedByMonthResults,
      averageMonthlyGain
    };
}

// Calculate the average months for doubling
export const calculateAverageMonthsToDouble = (
    startingBank,
    resultsByMonth 
    ) => {

    let lastDoubledBank = startingBank;
    let monthsSinceLastDouble = 0;
    let totalMonthsForDoubling = 0;
    let numberOfDoublings = 0;

    // After grouping the results by month
    Object.values(resultsByMonth).forEach((monthResult, index) => {
        // Check if the bank has doubled compared to the last doubled amount
        if (monthResult.bankAfterRace >= lastDoubledBank * 2) {
            numberOfDoublings++;
            totalMonthsForDoubling += monthsSinceLastDouble;
            lastDoubledBank = monthResult.bankAfterRace; // Update the last doubled bank amount
            monthsSinceLastDouble = 0; // Reset the counter
        } else {
            monthsSinceLastDouble++; // Increment the counter if the bank has not doubled
        }
    });

    if (numberOfDoublings > 0) {
        return (totalMonthsForDoubling / numberOfDoublings);
    } else {
        return 0;
    }
}