import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import "./Tables.css";

const SyndiResults = ({ results }) => {
  if (!results || results.length === 0) {
    return <div>Loading...</div>;
  }
  
  let weeklyResults = results.weekly;

  weeklyResults.forEach((weeklyResult, index) => {
    weeklyResult.id = index + 1;
  });

  const columns = [
    {
      field: "timestamp",
      headerName: "Date",
      width: 150,
      valueFormatter: (timestamp) => {
        const date = new Date(timestamp.value * 1000);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
      },
      valueGetter: (timestamp) => {
        return timestamp.value;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
          return cellParams1.value - cellParams2.value
      },
      headerClassName: 'header',
    },
    { 
      field: "deposit_total", 
      headerName: "Deposit Total", 
      width: 200,
      valueGetter: (params) => params.row.deposit_total,
      valueFormatter: ({ value }) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value),
      headerClassName: 'header',
    },
    { 
      field: "profit_loss_total", 
      headerName: "Profit / Loss Total", 
      width: 200,
      valueGetter: (params) => params.row.profit_loss_total,
      valueFormatter: ({ value }) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value),
      headerClassName: 'header',
    },
    { 
      field: "total_balance", 
      headerName: "Total Balance", 
      width: 200,
      valueGetter: (params) => params.row.total_balance,
      valueFormatter: ({ value }) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value),
      headerClassName: 'header',
    },
  ].map((column) => ({
    ...column,
    getCellStyle: (params) => {
      const color = params.rowIndex % 2 ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.05)';
      return { backgroundColor: color };
    },
  }));

  return (
    <div className="SyndiResults-table">
      <h2>Results Breakdown</h2>
      <DataGrid 
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'timestamp',
                sort: 'desc',
              },
            ]
          },
        }}
        rows={weeklyResults} 
        columns={columns} 
        className="DataGrid-cell"
      />
    </div>
  );
};

export default SyndiResults;
