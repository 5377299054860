const awsconfig = {
  Auth: {
    region: "ap-southeast-2",
    userPoolId: "us-east-1_ESzO0SLig",
    userPoolWebClientId: "qf147lpfc6ec3a78fc2ji6cvg",
  },  
  "apiGateway": {
    "endpoint": "https://api.mckinnel.me/v1"
  }
};

export default awsconfig;
