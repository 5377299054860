import React, { useState, useCallback } from 'react';

import { debounce } from 'lodash';

import { TextField, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from "@mui/material";

import { useTrifecta } from './Context/TrifectaContext';

const TrifectaControls = () => {

  const {
    betSize, 
    setBetSize,
    betSizeType, 
    setBetSizeType
  } = useTrifecta();

  // Initialize state
  const [inputValue, setInputValue] = useState(betSize);

  // Debounce the setter function
  const debouncedSetBetSizePercentage = useCallback(
    debounce((newValue) => setBetSize(newValue), 500), // 500ms delay
    [] // Dependencies array, empty means the debounced function will only be created once
  );

  const handleBetSizeTypeChange = (event) => {
    setBetSizeType(event.target.value);
  };

  // This handler updates the input value immediately
  // but delays the actual call to setBetSizePercentage
  const handleBetSizeChange = (event) => {
    const newValue = parseFloat(event.target.value);
    setInputValue(newValue);
    debouncedSetBetSizePercentage(newValue);
  };

  return (
    <div className="controls-container">
      <FormControl component="fieldset">
        <FormLabel component="legend">Bet Size Type</FormLabel>
        <RadioGroup
          row
          aria-label="bet-size-type"
          name="bet-size-type"
          value={betSizeType}
          onChange={handleBetSizeTypeChange}
        >
          <FormControlLabel value="percentage" control={<Radio />} label="Percentage" />
          <FormControlLabel value="flat" control={<Radio />} label="Flat" />
        </RadioGroup>
      </FormControl>
      
      {betSizeType === 'percentage' && (
        <div className="input-button-container">
          <p>Bet size (%)</p>
          <TextField 
            className="bet-size-input"
            label="" 
            type="number" 
            value={inputValue} 
            onChange={handleBetSizeChange} 
            InputLabelProps={{
              style: { color: '#757575' },
            }}
            inputProps={{
              style: { 
                color: '#333', // Text color
                backgroundColor: '#fff', // Background color
                padding: '8px', // Padding
              },
              min: 0, // Minimum value
              max: 100, // Maximum value
              step: 0.5, // Step size
            }}
          />
        </div>
      )}
      {betSizeType === 'flat' && (
        <div className="input-button-container">
          <p>Bet size ($)</p>
          <TextField 
            className="bet-size-input"
            label="" 
            type="number" 
            value={inputValue} 
            onChange={handleBetSizeChange} 
            InputLabelProps={{
              style: { color: '#757575' },
            }}
            inputProps={{
              style: { 
                color: '#333', // Text color
                backgroundColor: '#fff', // Background color
                padding: '8px', // Padding
              },
              min: 0, // Minimum value
              max: 1000, // Maximum value
              step: 10, // Step size
            }}
          />
        </div>
      )}
    </div>
  );
};

export default TrifectaControls;
