import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import "./Tables.css";

const Tips = ({ tips }) => {
  const flattenTips = tips.map((tip) => {
    return {
      ...tip,
      'details.selection': tip.details.selection,
      'details.track': tip.details.track,
      'details.race': tip.details.race,
      'details.tab': tip.details.tab,
      'details.sources': tip.details.sources,
      'details.sources_mix': tip.details.sources_mix,
      'details.percentage_of_bank': tip.details.percentage_of_bank,
    };
  });
  
  const columns = [
    {
      field: "timestamp",
headerName: "Date",
      width: 100,
      valueFormatter: (timestamp) => {
        const date = new Date(timestamp.value * 1000);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
      },
      valueGetter: (timestamp) => {
        return timestamp.value;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
          return cellParams1.value - cellParams2.value
      },
      headerClassName: 'header',
    },
    { 
      field: "details.selection", 
      headerName: "Selection", 
      width: 150,
      headerClassName: 'header',
    },
    { 
      field: "details.track", 
      headerName: "Track", 
      width: 150,
      headerClassName: 'header',
    },
    { 
      field: "details.race", 
      headerName: "Race", 
      width: 100,
      headerClassName: 'header',
    },
    { 
      field: "details.tab", 
      headerName: "Tab", 
      width: 100,
      headerClassName: 'header',
    },
    { 
      field: "details.percentage_of_bank", 
      headerName: "% of Bank", 
      width: 100,
      valueGetter: (params) => parseFloat(params.row.details.percentage_of_bank),
      headerClassName: 'header',
    },
  ].map((column) => ({
    ...column,
    getCellStyle: (params) => {
      const color = params.rowIndex % 2 ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.05)';
      return { backgroundColor: color };
    },
  }));

  return (
    <div className="Tips-table">
      <h2>Tips Received</h2>        
      <DataGrid 
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'timestamp',
                sort: 'desc',
              },
            ]
          },
        }}
        rows={flattenTips} 
        columns={columns} 
        getRowId={(row) => row.bet_id} 
        className="DataGrid-cell"
      />
    </div>
  );
};

export default Tips;
