import "./App.css";

import React, { useState, useEffect } from "react";

import { Amplify, Auth } from 'aws-amplify';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import awsconfig from "./aws-exports";

import LoginForm from "./LoginForm";
import ResultsTable from "./ResultsTable";
import ResultsChart from "./ResultsChart";

import TrifectaResults from "./Trifecta/TrifectaResults";
import { TrifectaProvider } from './Trifecta/Context/TrifectaContext';

import SyndiBets from "./SyndiBets";
import SyndiTransactions from "./SyndiTransactions";
import Tips from "./Tips";
import NewPasswordForm from "./NewPasswordForm";
import {
  handleLogin,
  handleLogout,
  handleNewPassword,
} from "./authHandlers";

Amplify.configure(awsconfig);


const App = () => {
  const [results, setResults] = useState([]);
  const [trifectaResults, setTrifectaResults] = useState([]);
  const [user, setUser] = useState(null);
  const [syndiBets, setSyndiBets] = useState([]);
  const [tips, setTips] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showNewPasswordForm, setShowNewPasswordForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedTab, setSelectedTab] = useState("Tips");

  useEffect(() => {

    const checkUserSession = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        setUser(currentUser);
      } catch (error) {
        console.error('No current user session found:', error);
      }
    };

    checkUserSession();
  }, []);

  useEffect(() => {

    const fetchData = async () => {
      if (!user) {
        console.log("User not authenticated.");
        return;
      }

      const apiUrl = awsconfig.apiGateway.endpoint;
      const idToken = user.getSignInUserSession().getIdToken().getJwtToken();

      // Fetching tips data
      const tipsResponse = await fetch(apiUrl + '/tips', {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      const tipsData = await tipsResponse.json();
      setTips(tipsData);

      // Fetching results data
      const resultsResponse = await fetch(apiUrl + '/syndi-results', {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      const resultsData = await resultsResponse.json();
      setResults(resultsData);

      // Fetching trifecta results data
      const trifectaResultsResponse = await fetch(apiUrl + '/trifecta-results', {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      const trifectaResultsData = await trifectaResultsResponse.json();
      setTrifectaResults(trifectaResultsData);

      // Fetching transaction data
      const transactionsResponse = await fetch(apiUrl + '/syndi-transactions', {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      const transactionData = await transactionsResponse.json();
      setTransactions(transactionData);

      // Fetching syndi bets data
      const syndiBetsResponse = await fetch(apiUrl + '/syndi-bets', {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      const syndiBetsData = await syndiBetsResponse.json();
      setSyndiBets(syndiBetsData);

    };

    fetchData();
  }, [user]);
  
  const handleLoginWrapper = async () => {
    const result = await handleLogin(username, password, setErrorMessage);
    console.log(result);
    if (result) {
      if (result.showNewPasswordForm) {
        setShowNewPasswordForm(true);
      }
      console.log(result.cognitoUser);
      setUser(result.cognitoUser);
    }
  };

  const handleLogoutWrapper = async () => {
    const success = await handleLogout();
    if (success) {
      setUser(null);
    }
  };  

  const handleNewPasswordWrapper = async (newPassword) => {
    await handleNewPassword(user, newPassword, setShowNewPasswordForm);
  };

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case "Tips":
        return <Tips tips={tips} />;
      case "Bets Placed":
        return <SyndiBets syndiBets={syndiBets} />;
      case "Transactions":
        return <SyndiTransactions transactions={transactions} />;
      case "Results":
        return (
          <>
            <ResultsChart results={results} />
            <ResultsTable results={results} />
          </>
        );
      case "Trifectas":
        return (
          <>
          <TrifectaProvider>
            <TrifectaResults rawResults={trifectaResults} />
          </TrifectaProvider>
          </>
        );
      case "Logout":
        return (
          <button onClick={handleLogoutWrapper} className="logout-button">Logout</button>
        )
      default:
        return null;
    }
  };
  
  return (
    <div className="App">
      <header className="App-header">
        {!user && !showNewPasswordForm && (
          <>
            <LoginForm onLogin={handleLoginWrapper} setUsername={setUsername} setPassword={setPassword} />
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </>
        )}

        {showNewPasswordForm && 
          <NewPasswordForm 
            onChangePassword={handleNewPasswordWrapper} 
          />
        }

        {user && !showNewPasswordForm && (
          <>
            <div className="App-tabs">
              <Tabs value={selectedTab} onChange={handleChangeTab} centered>
                <Tab label="Tips" value="Tips" />
                <Tab label="Bets Placed" value="Bets Placed" />
                <Tab label="Transactions" value="Transactions" />
                <Tab label="Results" value="Results" />
                <Tab label="Trifectas" value="Trifectas" />
                <Tab label="Logout" value="Logout" />
              </Tabs>
            </div>
            <div className="App-content">
              {renderTabContent()}
            </div>
          </>
        )}

      </header>
    </div>
  );

};

export default App;
