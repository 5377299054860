import React, { createContext, useState, useContext } from 'react';

const TrifectaContext = createContext();

export const useTrifecta = () => useContext(TrifectaContext);

export const TrifectaProvider = ({ children }) => {
  // All your state variables
  const [startingBank, setStartingBank] = useState(10000);
  const [finishingBank, setFinishingBank] = useState(0);
  const [netProfitLoss, setNetProfitLoss] = useState(0);

  const [betSizeType, setBetSizeType] = React.useState('percentage'); // or 'percentage'
  const [betSize, setBetSize] = useState(0);
  
  const [calculatedResults, setCalculatedResults] = useState([]);
  const [groupedResults, setGroupedResults] = useState([]);

  const [maxDrawdown, setMaxDrawdown] = useState(0);
  const [largestOddsWin, setLargestOddsWin] = useState(0);
  const [averageOdds, setAverageOdds] = useState(0);
  const [winningStreak, setWinningStreak] = useState(0);
  const [losingStreak, setLosingStreak] = useState(0);
  const [averageMonthsToDouble, setAverageMonthsToDouble] = useState(0);
  const [totalPercentageGain, setTotalPercentageGain] = useState(0);
  const [averageMonthlyGain, setAverageMonthlyGain] = useState(0);

  // ... other state variables ...

  // Bundle states and setters to provide
  const value = {
    averageOdds,
    setAverageOdds,
    averageMonthlyGain,
    setAverageMonthlyGain,
    startingBank,
    setStartingBank,
    finishingBank,
    setFinishingBank,
    netProfitLoss,
    setNetProfitLoss,
    betSizeType,
    setBetSizeType,
    betSize,
    setBetSize,
    calculatedResults,
    setCalculatedResults,
    groupedResults,
    setGroupedResults,
    averageMonthsToDouble,
    setAverageMonthsToDouble,
    maxDrawdown,
    setMaxDrawdown,
    largestOddsWin,
    setLargestOddsWin,
    winningStreak,
    setWinningStreak,
    losingStreak,
    setLosingStreak,
    totalPercentageGain,
    setTotalPercentageGain
    // ... other state variables ...
  };

  return <TrifectaContext.Provider value={value}>{children}</TrifectaContext.Provider>;
};
