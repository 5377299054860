import { Auth } from 'aws-amplify';

export const handleLogin = async (username, password, setErrorMessage) => {
  try {
    const user = await Auth.signIn(username, password);
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      return { cognitoUser: user, showNewPasswordForm: true };
    } else {
      setErrorMessage(""); // Reset the error message on successful authentication
      return { cognitoUser: user, showNewPasswordForm: false };
    }
  } catch (err) {
    console.error("Authentication error:", err);
    if (err.code === "NotAuthorizedException") {
      setErrorMessage("Incorrect username or password.");
    } else if (err.code === "UserNotFoundException") {
      setErrorMessage("User does not exist.");
    } else {
      setErrorMessage("An error occurred during authentication.");
    }
  }
};

export const handleLogout = async () => {
  try {
    await Auth.signOut();
    return true; // Indicate successful logout
  } catch (error) {
    console.error('Error signing out: ', error);
    return false; // Indicate failed logout
  }
};

export const handleNewPassword = async (user, newPassword, setShowNewPasswordForm) => {
  try {
    await Auth.completeNewPassword(
      user,
      newPassword,
    );
    setShowNewPasswordForm(false);
    console.log("Password changed successfully.");
  } catch (err) {
    console.error("Error changing password:", err);
  }
};